@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;600;700&display=swap');

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div[data-tippy-root] {
  will-change: transform;
}

.no-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-checkbox,
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  color: currentColor;
  background-color: #fff;
  border-color: rgb(229, 231, 235);
  border-width: 2px;
  height: 1.4em;
  width: 1.4em;
}

.form-checkbox {
  border-radius: 0.25rem;
}

.form-radio {
  border-radius: 50%;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.react-calendar {
  font-family: 'Comfortaa', cursive !important;
  border: 1px solid #ddddd0 !important;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: 0 !important;
}

.react-date-picker__button:focus,
.react-time-picker__button:focus {
  outline: none;
}
.react-date-picker__inputGroup__input:focus,
.react-time-picker__inputGroup__input {
  outline: none;
}

.react-date-picker__button,
.react-time-picker__button {
  font-size: 20px;
  color: rgb(156, 163, 175);
  padding: 0px 0px !important;
  margin-right: 8px;
  transition: color 200ms;
}
.react-date-picker__button:hover,
.react-time-picker__button:hover {
  color: rgb(135, 141, 151);
}

.react-tel-input {
  font-family: 'Comfortaa', cursive !important;
  font-size: inherit !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.react-tel-input .form-control {
  font-size: inherit !important;
  background: transparent !important;
  border: none !important;
  height: 100% !important;
  width: 100% !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.react-select__control {
  border: none !important;
  box-shadow: unset !important;
  border-radius: 9999px !important;
  padding: 4px 10px !important;
  cursor: auto !important;
}

.react-select__single-value {
  color: inherit !important;
}

.react-tel-input :disabled {
  cursor: auto !important;
}

.react-date-picker--disabled,
.react-time-picker--disabled {
  background-color: #f2f2f2 !important;
}
.react-select__control--is-disabled {
  background-color: #f2f2f2 !important;
}

[data-rmiz-btn-close]:focus,
[data-rmiz-btn-open]:focus {
  outline: none !important;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

/* .fc-daygrid-day {
  background-color: white;
} */
